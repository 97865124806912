import React, {Component} from 'react'
import '../styles/global.css'

export default class Collab extends Component {
  getComponent = key => {
    if (Array.isArray(this.props.children)) {
      return this.props.children.filter(c => c && c.key === key)
    } else {
      return this.props.children.key === key ? [this.props.children] : []
    }
  }

  render() {
    return (
      <div className="off-canvas right">
        <div className={`off-canvas-sidebar ${this.props.open ? 'active' : ''}`} style={{width: '330px', borderRadius: '0'}}>
          {this.getComponent('sidebar')}
        </div>

        <a className="off-canvas-overlay" onClick={this.props.onDismiss} />
        <div className="off-canvas-content">{this.getComponent('body')}</div>
      </div>
    )
  }
}
