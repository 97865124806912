import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import '../styles/footer.css'

function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              logo {
                light
              }
            }
          }
        }
      `}
      render={() => {
        return (
          <div>
            <div className="th-home-footer bg-white pt-8">
              <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
              {/* Address and icons */}

              {/* Body  wit sections */}
              {/* MOBILE SOCIAL */}
              <div className=" bg-white th-home--deskhid text-center py-4 " style={{marginBottom: '1rem'}}>
                <div className="containter grid-xl">
                  <div className="columns col-8 col-oneline col-mx-auto ">
                    <div className="column col-3  ">
                      <a href="https://www.facebook.com/gohomelight/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-square fa-lg" style={{color: '#0A87D8', fontSize: '14px'}} />
                      </a>
                    </div>
                    <div className="column col-3">
                      <a href="https://twitter.com/HomeLightApp" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-twitter-square fa-lg" style={{color: '#0A87D8', fontSize: '14px'}} />
                      </a>
                    </div>

                    <div className="column col-3  ">
                      <a href="https://www.linkedin.com/company/homelight" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin fa-lg" style={{color: '#0A87D8', fontSize: '14px'}} />
                      </a>
                    </div>
                    <div className="column col-3  ">
                      <a href="https://www.instagram.com/teamhomelight/?hl=en" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram fa-lg" style={{color: '#0A87D8', fontSize: '14px'}} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container  bg-white grid-xl px-2 text-small">
                <div className="columns ">
                  <div className="column  col-sm-12 col-md-4 col-3  mt-4 col-mx-auto th-home-footer-column ">
                    <div className="th-home-footer-content">
                      <p className="h6" style={{fontSize: '14px'}}>
                        Company
                      </p>
                      <p>
                        <a href="/privacy" style={{color: '#0A87D8', fontSize: '14px'}}>
                          Privacy Policy
                        </a>
                      </p>
                      <p>
                        <a href="/terms" style={{color: '#0A87D8', fontSize: '14px'}}>
                          Terms of Service
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="column  col-sm-12 col-md-4 col-3  mt-4 col-mx-auto th-home-footer-column">
                    <div className="th-home-footer-content">
                      <p className="h6" style={{fontSize: '14px'}}>
                        Content
                      </p>
                      <a href="https://active.disclosures.io/" style={{color: '#0A87D8'}}>
                        <p>Blog</p>
                      </a>
                      <a href="https://active.disclosures.io/" style={{color: '#0A87D8', fontSize: '14px'}}>
                        <p>Industry News</p>
                      </a>
                    </div>
                  </div>

                  <div className="column  col-sm-12 col-md-4 col-3  mt-4 col-mx-auto th-home-footer-column">
                    <div className="th-home-footer-content">
                      <p className="h6" style={{fontSize: '14px'}}>
                        Product
                      </p>
                      <p>
                        <a href="/demo" style={{color: '#0A87D8', fontSize: '14px'}}>
                          Watch a Demo
                        </a>
                      </p>
                      <a href="https://academy.disclosures.io/" style={{color: '#0A87D8', fontSize: '14px'}}>
                        <p>FAQ</p>
                      </a>
                      <p>
                        <a href="/pricing" style={{color: '#0A87D8', fontSize: '14px'}}>
                          Pricing
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="column  col-sm-12 col-md-4 col-3  mt-4 col-mx-auto th-home-footer-column">
                    <div className="th-home-footer-content">
                      <p className="h6" style={{fontSize: '14px'}}>
                        Help
                      </p>

                      <a href="https://academy.disclosures.io/video-training" style={{color: '#0A87D8', fontSize: '14px'}}>
                        <p>Help Videos</p>
                      </a>

                      <a href="mailto:support@disclosures.io" style={{color: '#0A87D8', fontSize: '14px'}}>
                        <p> Ask a Question</p>
                      </a>
                      <a href="https://survey.hsforms.com/1297WLSxLSEe4Yg5DcqlLIg2tp64" target="_blank" style={{color: '#0A87D8', fontSize: '14px'}}>
                        <p>Give us your feedback</p>
                      </a>
                    </div>
                  </div>

                  {/* MOBILE CONTACT */}
                  <div
                    className=" th-home--deskhid column col-sm-12 col-md-4 col-3  mt-4 col-mx-auto th-home-footer-column"
                    style={{marginBottom: '2rem', lineHeight: '2em'}}>
                    <div className="th-home-footer-content">
                      <p className="h6">CONTACT</p>
                      <p>
                        <a href="mailto:sales@disclosures.io" style={{color: '#0A87D8', fontSize: '14px'}}>
                          sales@disclosures.io
                        </a>
                      </p>
                      <p>
                        <a href="mailto:support@disclosures.io" style={{color: '#0A87D8', fontSize: '14px'}}>
                          support@disclosures.io
                        </a>
                      </p>
                    </div>
                  </div>
                  <div
                    style={{display: 'flex', flexDirection: 'row', padding: '0 2rem 2rem 2rem', flexWrap: 'wrap'}}
                    className=" th-home--deskhid column col-sm-12 col-md-4 col-3  mt-4 col-mx-auto th-home-footer-column ">
                    <p className=" bg-white th-home-footer  p text-small text-center" style={{marginBottom: '-.5rem'}}>
                      <b>Our Commitment to Accessibility:</b> Disclosures.io is committed to making our website accessible and user friendly to all. While we
                      are constantly working to improve, we welcome your feedback and accommodation requests. If you are having difficulty accessing or
                      navigating our website, or if you have any suggestions to improve accessibility,{' '}
                      <a href="mailto:support@disclosures.io" style={{color: '#0A87D8', fontSize: '14px', textDecoration: 'underline'}}>
                        please email our team
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>

              {/* DESKTOP CONTACT */}

              <div className="bg-white th-home--mobhid">
                <div className="th-home-footer bg-white th-home--mobhid">
                  <div style={{display: 'flex', flexDirection: 'row', padding: '2rem 5.5rem 0 5rem', flexWrap: 'wrap'}} className="container grid-xl px-2 ">
                    <div style={{flex: 1}}>
                      <p className="p text-small ">
                        <b>Disclosures.IO - HomeLight Listing Management</b>
                        <br />
                        100 California St, Ste 800, San Francisco, CA 94111
                      </p>
                    </div>

                    <div style={{flex: 1}}>
                      <p className="p text-small ">
                        <b>Contact us:</b>
                        <br />
                        <a href="mailto:sales@disclosures.io" style={{color: '#0A87D8', fontSize: '14px'}}>
                          sales@disclosures.io
                        </a>
                        &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                        <a href="mailto:support@disclosures.io" style={{color: '#0A87D8', fontSize: '14px'}}>
                          support@disclosures.io
                        </a>
                      </p>
                    </div>

                    <div>
                      <a href="https://www.facebook.com/gohomelight/" target="_blank" rel="noopener noreferrer" universal="true">
                        <i className="fab fa-facebook-square" style={{fontSize: '28px', padding: '0 0.5rem 0 0.5rem', color: '#0A87D8', fontSize: '14px'}} />
                      </a>
                      <a href="https://twitter.com/HomeLightApp" target="_blank" rel="noopener noreferrer" universal="true">
                        <i className="fab fa-twitter" style={{fontSize: '28px', padding: '0 0.5rem 0 0.5rem', color: '#0A87D8', fontSize: '14px'}} />
                      </a>
                      <a href="https://www.linkedin.com/company/homelight" target="_blank" rel="noopener noreferrer" universal="true">
                        <i className="fab fa-linkedin" style={{fontSize: '28px', padding: '0 0.5rem 0 0.5rem', color: '#0A87D8', fontSize: '14px'}} />
                      </a>
                      <a href="https://www.instagram.com/teamhomelight/?hl=en" target="_blank" rel="noopener noreferrer" universal="true">
                        <i className="fab fa-instagram" style={{fontSize: '28px', padding: '0 0.5rem 0 0.5rem', color: '#0A87D8', fontSize: '14px'}} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{display: 'flex', flexDirection: 'row', padding: '0 5.5rem 0rem 5rem', flexWrap: 'wrap'}}
                className="th-home--mobhid  th-home-footer container bg-white grid-xl px-2 ">
                <p className=" bg-white th-home-footer  p text-small text-left">
                  <b>Our Commitment to Accessibility:</b> Disclosures.io is committed to making our website accessible and user friendly to all. While we are
                  constantly working to improve, we welcome your feedback and accommodation requests. If you are having difficulty accessing or navigating our
                  website, or if you have any suggestions to improve accessibility,{' '}
                  <a href="mailto:support@disclosures.io" style={{color: '#0A87D8', fontSize: '14px', textDecoration: 'underline'}}>
                    please email our team
                  </a>
                  .
                </p>

                <p className=" bg-white th-home-footer   p text-small text-left" style={{}}>
                  Disclosures.IO - HomeLight Listing Management © 2021 BRIGHTHOME INC
                </p>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default Footer
