import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        google {
          tagManager
          analytics
        }
        intercom
        disco {
          base
          exp
          icon
        }
        logo {
          favicon
        }
      }
    }
  }
`

function SEO({description, lang, meta, keywords, title}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const favicon = data.site.siteMetadata.logo.favicon
        const disco = data.site.siteMetadata.disco
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s ${data.site.siteMetadata.title}`}
            meta={[
              {
                meta,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
            link={[
              {rel: 'icon', type: 'image/png', href: favicon},
              {rel: 'stylesheet', type: 'text/css', href: disco.base},
              {rel: 'stylesheet', type: 'text/css', href: disco.exp},
              {rel: 'stylesheet', type: 'text/css', href: disco.icon},
              {rel: 'stylesheet', type: 'text/css', href: 'https://use.fontawesome.com/releases/v5.0.13/css/all.css'},
            ]}>
            {data.site.siteMetadata.google.analytics && <script async src="https://www.google-analytics.com/analytics.js" />}
            {data.site.siteMetadata.google.analytics && (
              <script>
                {`
                window.ga=window.ga||function(){(ga.q = ga.q || []).push(arguments)};
                ga.l=+new Date;
                ga('create', '${data.site.siteMetadata.google.analytics}', 'auto');
                ga('send','pageview');
              `}
              </script>
            )}
            {data.site.siteMetadata.google.tagManager && (
              <script async src={`https://www.googletagmanager.com/gtm.js?id=${data.site.siteMetadata.google.tagManager}`} />
            )}
            {data.site.siteMetadata.google.tagManager && (
              <script>
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${data.site.siteMetadata.google.tagManager}');
              `}
              </script>
            )}
            {/* {data.site.siteMetadata.intercom && (
              <script>
                {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${
                  data.site.siteMetadata.intercom
                }';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
              `}
              </script>
            )}
            {data.site.siteMetadata.intercom && (
              <script>
                {`
                window.Intercom("boot", {
                  app_id: "${data.site.siteMetadata.intercom}"
                });
                `}
              </script>
            )} */}
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default SEO
